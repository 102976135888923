import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Markdown from 'react-markdown';

const Impressum = ({data}) => (
    <Layout>
        <Helmet>
            <title>{data.strapiCompanyData.name} | {data.strapiLegal.seo.title}</title>
        </Helmet>
        <section className="shadow-lg bg-static-sites text-white px-5 rounded">
            <div className="container mx-auto pb-3">
                <h2 className="text-4xl">{data.strapiLegal.seo.title}</h2>
                <Link className="text-white hover:text-white hover:underline" to="/">zurück zur Startseite</Link>
            </div>
        </section>
        <section className="pt-5 px-5">
            <div className="container mx-auto">
                <Markdown children={data.strapiLegal.content} />
            </div>
        </section>
    </Layout>
);

// export page query
export const query = graphql`
query ImprintContent {
  strapiLegal(seo: {title: {eq: "Impressum"}}) {
    seo {
      title
      description
    }
    content
  }
  strapiCompanyData {
    name
  }
}
`

export default Impressum;
